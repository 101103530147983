import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RTLBox from "../RTLBox";
import * as yup from "yup";
import { useFormik } from "formik";
import { Close, Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import { Store } from "../../context/DataStore";
import {UploadFileImages} from "./UploadFileImages";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

export default function AddNewPastner({ items, fun ,statistics }) {
  const [logo, setLogo] = useState(items?.company_logo || "");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const {enqueueSnackbar} = useSnackbar()

  const formData = new FormData();
  const { i18n } = useTranslation();
  const { options } = Store();
  const regex = /(05|01)[0-9]{8}/;
  async function openHandler() {
    await getItemsDetails().then(() => setOpen(true));
  }
  const getItemsDetails = async () => {
    await axios
      .get(`/common/search-user/${items?.mobile}`, { headers: options })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data[0]);
          setData(res.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validationSchema = yup.object({
    partner_name: yup
      .string()
      .min(2)
      .required(i18n.t("AddNewClient.partner_name_required")),
    email: yup.string().email().required(i18n.t("AddNewClient.email_required")),
    website: yup.string().required(i18n.t("AddNewClient.websiteـrequired")),
    google_link: yup
      .string()
      .required(i18n.t("AddNewClient.google_linkـrequired")),
    phone: yup
      .string()
      .matches(regex, i18n.t("g.vaild_phone"))
      .max(10, i18n.t("g.vaild_phone_number"))
      .required(i18n.t("AddNewClient.phone_required"))
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      partner_name: "",
      phone: "",
      website: "",
      google_link: "",
      email: ""
    },
    onSubmit: async (values) => {
      const newPartner = {
        name: values.partner_name,
        mobile: values.phone,
        location_link: values.google_link.startsWith("https://")
          ? values.google_link
          : `https://${values.google_link}`,
        website: values.website.startsWith("https://")
          ? values.website
          : `https://${values.website}`,
        email: values.email
      };
      if (logo) {
        formData.append("logo", logo);
      }
      if (data) {
        await axios
          .put(`partners/update/${data.id}`, newPartner, { headers: options })
          .then((res) => {
            if (res.status === 200) {
              uploader(res.data.data.id).then((img) => {
                fun();
                formik.initialValues = {};
                setOpen(false);
              });
            }
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, {variant: "error"});
          });
      } else {
        await axios
          .post("/partners/register", newPartner, { headers: options })
          .then((res) => {
            if (res.status === 200) {
              uploader(res.data.data.id).then((img) => {
                fun();
                setOpen(false);
                statistics()
                formik.handleReset();
              });
            }
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, {variant: "error"});
          });
      }
    }
  });
  const uploader = async (id) => {
    await axios
      .post(`/partners/uploader/logo?partner_id=${id}`, formData, {
        headers: options
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function logoChange(e) {
    setLogo(e.target.files[0]);
    formik.values.company_logo = e.target.files;
  }
  function deleteLogo() {
    setLogo("");
    formik.values.company_logo = "";
  }
  useEffect(() => {
    formik.values.partner_name = data?.name;
    formik.values.website = data?.website;
    formik.values.google_link = data?.location_link;
    formik.values.email = data?.email;
    formik.values.phone = data?.mobile;
  }, [data]); 
  
  return (
    <>
      {items ? (
        <IconButton onClick={openHandler}>
          <Edit color="primary" />{" "}
        </IconButton>
      ) : (
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            bgcolor: "#CCDFF2",
            color: "#44A5FF",
            ":hover": { bgcolor: "#44A5FF", color: "#CCDFF2" }
          }}
        >
          {i18n.t("AddNewClient.partner_title")}
        </Button>
      )}
      <Dialog open={open} fullWidth>
        <Box p={2}>
          <IconButton
            onClick={() => {
              formik.handleReset(setOpen(false));
            }}
          >
            <Close />
          </IconButton>
        </Box>
        {data ? (
          <Typography
            textAlign={"center"}
            variant="h5"
            fontFamily={"Tajawal-b"}
          >
            {i18n.t("AddNewClient.partner_edit")}
          </Typography>
        ) : (
          <Typography
            textAlign={"center"}
            variant="h5"
            fontFamily={"Tajawal-b"}
          >
            {i18n.t("AddNewClient.partner_title")}
          </Typography>
        )}

        <DialogContent>
          <RTLBox>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item md={6} my={1}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="partner_name"
                    value={formik.values.partner_name || data?.name}
                    label={i18n.t("AddNewClient.partner_name")}
                    inputProps={{ type: "text" }}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.partner_name &&
                      Boolean(formik.errors.partner_name)
                    }
                    helperText={
                      formik.touched.partner_name && formik.errors.partner_name
                    }
                  />
                </Grid>
                <Grid item md={6} my={1}>
                  <TextField
                    size="small"
                    fullWidth
                    value={formik.values.phone || data?.mobile}
                    label={i18n.t("AddNewClient.phone")}
                    variant="outlined"
                    name="phone"
                    inputProps={{ type: "text", maxLength: 10 }}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    placeholder={"05"}
                  />
                </Grid>
                <Grid item md={6} my={1}>
                  <TextField
                    size="small"
                    fullWidth
                    value={formik.values.email || data?.email}
                    label={i18n.t("AddNewClient.email")}
                    variant="outlined"
                    name="email"
                    inputProps={{ type: "email" }}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>

                <Grid item md={6} my={1}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="website"
                    value={formik.values.website || data?.website}
                    label={i18n.t("AddNewClient.website")}
                    inputProps={{ type: "text" }}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.website && Boolean(formik.errors.website)
                    }
                    helperText={formik.touched.website && formik.errors.website}
                  />
                </Grid>
                <Grid item md={12} my={1}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="google_link"
                    value={formik.values.google_link || data?.location_link}
                    label={i18n.t("AddNewClient.google_link")}
                    inputProps={{ type: "text" }}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.google_link &&
                      Boolean(formik.errors.google_link)
                    }
                    helperText={
                      formik.touched.google_link && formik.errors.google_link
                    }
                  />
                </Grid>
                <Grid item md={6} my={1}>
                  <Button>
                    <label htmlFor="company_logo">
                      {i18n.t("AddNewClient.add_company_logo")}
                    </label>
                  </Button>
                  <input
                    hidden={true}
                    name="company_logo"
                    id="company_logo"
                    type="file"
                    accept="image/png,image/jpeg"
                    onChange={(e) => {
                      logoChange(e);
                    }}
                  />
                  {logo && (
                    <Box position={"relative"} width={"110px"}>
                      <img
                        src={
                          data ? data.company_logo : URL.createObjectURL(logo)
                        }
                        alt={i18n.t("AddNewClient.company_logo")}
                        width={100}
                      />
                      <IconButton
                        onClick={deleteLogo}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 1,
                          bgcolor: "#f6f6f6b4"
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Box>
                <UploadFileImages  fun={getItemsDetails}  set={setLogo} data={data} baseUrl="partners" maxImages={9} />
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"end"}
                px={4}
              >
                <LoadingButton
                  sx={{
                    borderRadius: "15px 50px",
                    bgcolor: "#2391BC",
                    fontFamily: "Tajawal-b",
                    width: "120px"
                  }}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  {!data
                    ? i18n.t("AddNewClient.add")
                    : i18n.t("AddNewClient.edit")}
                </LoadingButton>
              </Box>
            </form>
          </RTLBox>
        </DialogContent>
      </Dialog>
    </>
  );
}
