import {
  DoneAllOutlined,
  LocationOnOutlined,
  SquareFootOutlined
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Divider,
  List,
  ListItem,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import {avatar, logo_1} from "../../img";
import ImagesSwiper from "./ImagesSwiper";
import AddNewClient from "../admin/AddNewClient";
import {useTranslation} from "react-i18next";
import AutoplaySlider from "./AutoplaySlider";

export default function ProductCard({data}) {
  const {i18n} = useTranslation();
  return (
    <Card
      sx={{
        borderRadius:
          i18n.language === "arEG"
            ? "100px 20px 0px 0px"
            : "20px 100px 0px 0px",
        position: "relative",
        height: 600
      }}
    >
      <Box position={"absolute"} zIndex={1000} top={"42%"} right={"10px"}>
        <ImagesSwiper data={data?.large_files} id={data.id} />
      </Box>
      <Box
        position={"absolute"}
        top={"4%"}
        zIndex={1001}
        right={i18n.language === "arEG" && "10px"}
        left={i18n.language === "enUS" && "10px"}
      >
        <Typography
          bgcolor={"#2fa5d4"}
          variant="caption"
          p={1}
          borderRadius={"10px"}
          color={"#fff"}
          textAlign={"right"}
        >
          {data?.status?.title}
        </Typography>
      </Box>
      <a href={`${process.env.REACT_APP_BASE_URL}real_estate_units/${data.id}`} style={{textDecoration: "none"}}>
        <div style={{maxHeight: "300px", overflow: "hidden"}}>
          <AutoplaySlider data={data?.medium_file}  id={data.id}/>
        </div>
        {/* <CardMedia
          component={"img"}
          alt={data.title}
          src={data?.medium_file[0].replace(data.id<70? 'api-digitalline.glow-host.com' : 'api.digitalline.sa', 'api.digitalline.sa') || logo_1}
          width={"100%"}
          height={300}
          sx={{objectFit :"fill"}}
        /> */}
        
      </a>
      <List>
        <ListItem>
          <Typography component={"h2"} variant="h">
          <a href={`${process.env.REACT_APP_BASE_URL}real_estate_units/${data.id}`} style={{textDecoration: "none"}}>{data?.name}</a>
          </Typography>
        </ListItem>
        <ListItem>
          <LocationOnOutlined sx={{color: "#666666"}} />
          <Typography
            component={"a"}
            href={data?.location}
            target="_blank"
            variant="subtitle1"
            color="#666666"
          >
            {data?.city.title}
            {" ,"}
            {data?.area}
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Box width={"100%"} display={"flex"} alignItems={"center"}>
            <SquareFootOutlined />
            <Typography px={0.5} variant="subtitle1" color="#666666">
              {" "}
              m <sup>2</sup>{" "}
            </Typography>
            <Typography variant="subtitle1" color="#666666">
              {" "}
              {data?.space}{" "}
            </Typography>
          </Box>
          <Typography
            component={"h6"}
            display={"flex"}
            gap={1}
            variant="h6"
            color="#666666"
          >
            {data?.price}{" "}
            <Typography component={"samp"} variant="body1">
              ر.س
            </Typography>{" "}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <Box>
              <DoneAllOutlined
                sx={
                  i18n.language === "enUS"
                    ? {
                        position: "absolute",
                        bottom: "0px",
                        left: "12%",
                        zIndex: "2",
                        color: "#fff",
                        backgroundColor: "#2391BC",
                        borderRadius: "50%"
                      }
                    : {
                        position: "absolute",
                        bottom: "0px",
                        right: "12%",
                        zIndex: "2",
                        color: "#fff",
                        backgroundColor: "#2391BC",
                        borderRadius: "50%"
                      }
                }
              />
              <Avatar src={avatar} />
            </Box>
            <Typography variant="body1" width={"80%"} color="#666666">
              {data?.developer_name}
            </Typography>
          </Box>
          <AddNewClient url={"/client/request/create"} card />

          {/* <UserApplyForm/> */}
        </ListItem>
        <Tooltip title={data?.description}>
        <ListItem tool style={{direction:"rtl", textAlign: "right"}} >{data?.description.substring(0, 100)}...</ListItem>
        </Tooltip>
      </List>
    </Card>
  );
}
