import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function AutoplaySlider({ data, id=0, max=70}) {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
  
    return (
      <Slider {...settings}>
        {data?.map((image, index) => (
          <div key={index}>
            <img width={"100%"} height={300} src={image.replace(id<max? 'api-digitalline.glow-host.com' : 'api.digitalline.sa', 'api.digitalline.sa')} alt={`Image ${index}`} />
          </div>
        ))}
      </Slider>
    );
  }