import {Box, Grid, Container, Stack, Pagination, Typography} from "@mui/material";
import React, {useEffect,} from "react";
import FilterBox from "../components/productPage/FilterBox";
import ProductCard from "../components/productPage/ProductCard";
// import {ptoductCarts} from "../data/Lists";
import {Store} from "../context/DataStore";
import { useGetData } from "../hooks/getData";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {items_bg , liens} from "../img"

export default function RealEstate() {
  const { options } = Store()
  const {i18n} =useTranslation()
  const { data, setData} = useGetData('/real-estate-devs/items') 
  const {setShow , sortData} = Store();

  useEffect(() => {
    setShow(false);
  }, []);

  const changeHandel = async (e, p) => {
    await axios
      .get(`/real-estate-devs/items?page=${p}`, {headers: options})
      .then((res) => {
        setData(res.data.data);
      });
  };
  
  return (
    <Box>
              {/* <img src={liens} alt="liens"/> */}

      <Box
        width={'80%'}
        margin={'auto'}
        height={300}
        borderRadius={5}
        position={'relative'}
        sx={{
          backgroundImage:`url(${items_bg})`,
          backgroundSize:"cover", backgroundRepeat:"no-repeat" , backgroundPosition :"center center"
        }}
        className="lien-pic"
        display={'flex'} justifyContent={'center'}alignItems={'center'}
      >
        <FilterBox fun={setData} />
      </Box>
      <Container>
        {data?.data?.length === 0 ? (
          <Typography
            p={5}
              variant="h6"
              fontFamily={"Tajawal-b"}
              textAlign={"center"}
            >
              {i18n.t("g.notFound")}
            </Typography>

        ): (
        <Grid container spacing={2} py={10}>
          { (sortData||data?.data)?.map((ele, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <ProductCard data={ele} />
            </Grid>
          ))}
        </Grid>
            
        ) }
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          py={2}
        >
          <Stack spacing={2} sx={{direction: "ltr"}}>
            <Pagination
              count={data?.meta?.last_page}
              variant="outlined"
              shape="rounded"
              onChange={changeHandel}
            />
          </Stack>
        </Box>

      </Container>
    </Box>
  );
}
