import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { appleStore, emkan, EmkanWeb, googlePlay } from "../img";
import { useTranslation } from "react-i18next";
import { Store } from "../context/DataStore";
import EmkanConfig from "../components/EmkanConfig";

export default function PaymentSol() {
  const { i18n } = useTranslation();
  const { setShow } = Store();
  useEffect(() => {
    setShow(false);
  }, []);

  return (
    <Box height={"100%"} position={"relative"} pb={5}>
      <Typography
        fontFamily={"Tajawal-b"}
        fontWeight={800}
        component={"h1"}
        variant="h4"
        textAlign={"center"}
        py={2}
      >
        {i18n.t("HeaderMenu.payment")}
      </Typography>

      <Box py={5}>
        <Container sx={{ height: "" }}>
          <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item md={8} bgcolor={"#F3FAFC"} borderRadius={5}>
            <Box p={5}>
              <h2 lineHeight={2} style={{textAlign: "center"}}>
                لتحرير القسائم الشرائية اتبع الخطوات التالية
              </h2>
            </Box>
            <Box pr={5}>
                <h3 style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar > 1 </Avatar>
                  {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:"100%" }}> */}
                    <span style={{ paddingLeft: "20px" }}>زيارة أحد شركائنا لطلب المنتج أو الخدمة من{' '}</span>
                    <a href={`${process.env.REACT_APP_BASE_URL}partners`}>هـــنـــــــــــــــا</a>
                  {/* </div> */}
                </h3>
                <h3  style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar>2</Avatar>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:"100%" }}>
                    <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>التسجيل في امكان عن طريق:</div>
                    
                    <div >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.emkan&hl=ar"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img width={150} src={googlePlay} alt="Google Play" />
                      </a>
                      <a
                        href="https://apps.apple.com/us/app/emkan-finance/id1508990688"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img width={150} src={appleStore} alt="Apple Store" />
                      </a>
                    </div>
                  </div>
                </h3>

                <h3 style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar > 3 </Avatar>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:"100%" }}>                    <span style={{ padding: "20px" }}>
                    طلب قسيمة شرائية باسم (الخط الرقمي) في قائمة (متاجر تمويل المشتريات):  
                    </span> 
                    <a
                        href="https://www.emkanfinance.com.sa/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img pl={5} width={75} src={EmkanWeb} alt="Emkan امكان" />
                    </a>
                  </div>
                </h3>
                <h3 style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar > 4 </Avatar>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:"100%" }}>
                    <span style={{ padding: "20px" }}>
                    من هنا يمكنك تحرير قسيمتك الشرائية لشركاء (الخط الرقمي) واستلام المنتج أو الخدمة:  
                    </span> 
                    <EmkanConfig /> 
                  </div>
                </h3> 
            </Box>
            
          </Grid>


            <Grid
              height={"50vh"}
              item
              md={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                p={2}
                border={"2px solid #5494AC"}
                borderRadius={"97px 15px 97px 16px"}
                width={"100%"}
                bgcolor={"#3a3672"}
                height={"100%"}
                display={"flex"}
                alignItems={"center"}
              >
                <img src={emkan} alt="Tajawal" width={"100%"} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
