import React, { useState } from "react";
import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import { Delete, Upload } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import ProgressImages from "./ProgressImages";
import { Store } from "../../context/DataStore";
import axios from "axios";
import { useTranslation } from "react-i18next";

export const UploadFileImages = ({ data, fun, set, baseUrl, maxImages }) => {
  const { i18n } = useTranslation();
  const maxnumberPic = maxImages;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { files, setFiles, options } = Store();
  const [isExistImg, setIsExistImg] = useState(null);
  const getImage = (images) => {
    closeSnackbar();
    if (images.length > maxImages) {
      enqueueSnackbar(`${i18n.t("g.error_pic_more")}`, { variant: "error" });
      return;
    }
    setFiles(images);
  };
  const deleteImage = (ele) => {
    const filter = Object.values(files).filter((x) => x.name !== ele);
    setFiles(filter);
  };
  const addMore = async (more) => { 
    if (more.length + data?.small_files?.length > maxImages) {
      enqueueSnackbar(`${i18n.t("g.error_pic_more")}`, { variant: "error" });
      return;
    }
    const list = Object.values(files).concat(Object.values(more));
    setFiles(list);
    const formData = new FormData();
    Object.keys(list).map((index) => {
      return formData.append(`files[]`, list[index]);
    });
    
    await axios.post(
      `/${baseUrl}/${data.id}/files`,
      formData,
      { headers: options }
    ).then((response) =>{
      enqueueSnackbar(`${response.data.message}`, { variant: "success" });
      setFiles([])
      fun();

    }).catch((error) => {
  enqueueSnackbar(`${error.response.data.message}`, { variant: "error" });
  setFiles([])
    });
  };
  const deletFromDataBase = async (item) => {
    await axios
      .delete(`${baseUrl}/${data.id}/files`, {
        headers: options,
        data: { 
            filename: item
        }
      })
      .then((res) => {
        enqueueSnackbar(`${res.data.message}`, { variant: "success" });
        fun();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Typography py={4}> {i18n.t("g.add_pic")}</Typography>
      <Box width={"100%"} border={"2px dashed #31a3d0"} borderRadius={5}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={200}
        >
          <Box>
            
              <Box>
                <label
                  style={{
                    backgroundColor:
                      (files.length + data
                        ? data?.small_files?.length
                        : 0) === maxnumberPic && "gray"
                  }}
                  htmlFor="uploadMore"
                  className="upload"
                >
                  {i18n.t("g.add_pic_more")}{" "}
                  {data
                    ? maxnumberPic -
                      (data?.small_files?.length + files.length || 0)
                    : maxnumberPic - files.length}{" "}
                  {i18n.t("g.pic")}
                </label>
                <input
                  name="uploadMore"
                  type="file"
                  id="uploadMore"
                  hidden
                  multiple
                  accept="image/png,image/jpeg"
                  disabled={
                    files.length === maxnumberPic ||
                    files.length + data?.small_files?.length === maxnumberPic
                  }
                  max={maxnumberPic}
                  onChange={(e) => { 
                    addMore(e.target.files);
                  }}
                />
              </Box> 
          </Box>
        </Box>
        <Box>
          <List>
            {data ? (
              <>
                {data?.small_files?.map((file, index) => (
                  <React.Fragment key={file}>
                    <ListItem>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                        alignItems={"center"}
                        px={2}
                      >
                        <img
                          src={(isExistImg || data)?.small_files[index]}
                          alt=""
                          width={50}
                        />
                        <IconButton onClick={() => deletFromDataBase(file)}>
                          <Delete color="error" />
                        </IconButton>
                      </Box>
                    </ListItem>
                  </React.Fragment>
                ))}
                {Object.values(files).map((x, index) => (
                  <ProgressImages
                    key={index}
                    data={x}
                    deleteImage={deleteImage}
                  />
                ))}
              </>
            ) : (
              <>
                {Object.values(files).map((x, index) => (
                  <ProgressImages
                    key={index}
                    data={x}
                    deleteImage={deleteImage}
                  />
                ))}
              </>
            )}
          </List>
        </Box>
      </Box>
    </>
  );
};
