import React from "react";
 import {
  Box,
  Container,    
  Grid, 
} from "@mui/material";
import { useGetData } from "../hooks/getData";
import { useParams } from "react-router-dom"; 
import ProductCardView from "../components/productPage/ProductCardView";
import LinearProgress from '@mui/material/LinearProgress'; 
import MainBanner from "../components/MainBanner";


export default function RealStateUnitView() {   
  const { id } = useParams();
  const { data, loading, setData} = useGetData(`/real-estate-devs/item/${id}`) ;  

  return (
    <Box mb={35}>
      <Container>
        <MainBanner />
        <br /> 
        {loading && <LinearProgress />}
        {!loading &&  
        <Grid item xs={12} sm={12} md={12}>
            {data.video_url && (<iframe 
                  width="100%" 
                  height="500" 
                  src={data.video_url} 
                  title={data.title} 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen>
              </iframe>)}
            <ProductCardView data={data} />
        </Grid> } 
      </Container> 
    </Box>
  );
}
