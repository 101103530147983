import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import {useTranslation} from "react-i18next";
import {List, ListItemButton} from "@mui/material";
import {ExpandMoreOutlined} from "@mui/icons-material";
import {useGetData} from "../../hooks/getData";
import axios from "axios";
import {Store} from "../../context/DataStore";

export default function BasicMenu({title, outLien, items, fun , findUrl}) {
  const {data} = useGetData("/real-estate-devs/items");
  const {i18n} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const open = Boolean(anchorEl);
  const {options, setSetSortData} = Store();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  async function getFilter(id) {
    if (id === "price") {
      const sortItem = data?.data?.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else {
          return 1;
        }
      });
      setSetSortData(sortItem);
      handleClose();
      return;
    }
    if (id === 0) {
      setSetSortData(null)
      fun(data);
      handleClose()
      return;
    } else {
      setSetSortData(null)
      await axios
        .get(`${findUrl}/${id}`, {headers: options})
        .then((res) => {
          fun(res.data.data);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          handleClose();
        });
    }
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant={outLien ? "outlined" : "contained"}
        endIcon={<ExpandMoreOutlined sx={{mr: 2}} />}
        sx={{px: 2, py: 1.5}}
      >
        {i18n.t(selected?? title)}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <List sx={{width:items?.length > 10 ?  "200px" :"130px"}}>
          <ListItemButton
            sx={{width: "100%"}}
            onClick={() => {
              getFilter(0);
            }}
          >
            {i18n.t("g.all")}
          </ListItemButton>

          {items?.map((item, index) => (
            <ListItemButton
              sx={{width: "100%"}}
              key={index}
              onClick={() => {
                getFilter(item.id);
                setSelected(item.title)
              }}
            >
              {i18n.t(item.title)}
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </div>
  );
}
